
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

const minted = require("@/minted.json");

interface IAttribute {
    "trait_type": string,
    "value": string
}

interface IPig {
    "name": string,
    "image": string,
    "edition": any,
    "attributes": IAttribute[],
    "rank": any,
    "unset"?: boolean
}

@Component({
    name: "Rarity"
})
export default class Rarity extends Vue {
    minted: any = minted;
    totalPigs = 0;
    displayed = 15;
    attributes: any = [];
    filters: any = {
        showUnrevealed: true,
        query: "",
        sort: "rank",
        traits: []
    };

    pigs: IPig[] = [];

    @Watch('filters.query')
    onQuery() {
        this.initData();
    }

    @Watch('filters.sort')
    onSort() {
        this.initData();
    }

    @Watch('filters.showUnrevealed')
    onUnknown() {
        this.initData();
    }

    get slicedPigs() {
        return this.pigs.slice(0, this.displayed);
    }

    initPigs() {
        let pigs:any = [];
        let i = 0, len = 1110;

        while(i < len) {
            let attr = [
                {
                    "trait_type": "Background",
                    "value": "???"
                },
                {
                    "trait_type": "Character",
                    "value": "???"
                },
                {
                    "trait_type": "Clothing",
                    "value": "???"
                },
                {
                    "trait_type": "Eyes",
                    "value": "???"
                },
                {
                    "trait_type": "Hats",
                    "value": "???"
                },
                {
                    "trait_type": "Mouth",
                    "value": "???"
                },
                {
                    "trait_type": "Lineage",
                    "value": "???"
                }
            ]
            if (this.filters.sort === "rank") {
                pigs.push(this.minted.find((p:any) => p.rank === i) || {rank: i, edition: "??", unset: true, attributes: attr});
                ++i;
            } else {
                pigs.push(this.minted.find((p:any) => p.edition === i) || {edition: i, rank: "??", unset: true, attributes: attr});
                ++i;
            }
        }
        this.pigs = pigs.filter((pig: IPig) => {

            if (!this.filters.showUnrevealed) {
                if (pig.edition === "??" || pig.rank === "??") {
                    return;
                }
            }
            pig.attributes?.sort((a: any, b: any) => b.trait_type < a.trait_type ? 1 : b.trait_type > a.trait_type ? -1 : 0);

            let queries;

            if (this.filters.query.trim()) {
                queries = this.filters.query.trim().toLowerCase().split(/ /gmi);
            }

            let matchRank = false, matchEdition = false, matchAttribute = false, queryMatch = true;

            if (queries?.length) {
                if (queries.includes('rank')) {
                    matchRank = queries.filter((q: string) => pig.rank + 1 === parseInt(q)).length;
                } else if (queries.includes('id')) {
                    matchEdition = queries.filter((q:string) => pig.edition === parseInt(q)).length;
                } else {
                    let c = queries.length;

                    matchRank = queries.filter((q:string) => pig.rank + 1 === parseInt(q)).length;
                    matchEdition = queries.filter((q:string) => pig.edition === parseInt(q)).length;
                    matchAttribute = queries.filter((q:string) => {
                        return (pig.attributes?.filter((attr: any) => {
                                return attr.value.toLowerCase().indexOf(q) !== -1;
                            }) || []).length;
                    }).length === c;
                }

                queryMatch = matchRank || matchEdition || matchAttribute;
            }

            const traits:any = this.filters.traits;

            return queryMatch
                && (!traits.length
                    || !traits
                        .map((t:any) => !!pig.attributes.find((a:any) => a.trait_type === t.attr && a.value === t.name))
                        // @ts-ignore;
                        .includes(false)
                );
        }).sort((a: IPig, b: IPig) => {
            if (this.filters.sort === "rank") {
                return a.rank - b.rank;
            } else {
                return a.edition - b.edition;
            }
        })
        this.totalPigs = this.pigs.length;
    }

    toggleCollapse(attr: any) {
        this.attributes.forEach((a: any) => {
            if (a.name !== attr.name) {
                a.collapsed = false;
            }
        });
        attr.collapsed = !attr.collapsed;
    }

    checkTrait(trait: any) {
        if (trait.count) {
            trait.checked = !trait.checked;

            const pos = this.filters.traits.findIndex((t:any) => t.name === trait.name && t.attr === trait.attr);

            if (pos !== -1) {
                this.filters.traits.splice(pos, 1);
            } else {
                this.filters.traits.splice(this.filters.traits.length - 1, 0, trait);
            }

            this.initData();
        }
    }

    loadMore() {
        this.displayed += 15;
        this.initData();
    }

    getTrait(filter: any) {
        const attr: any = this.attributes.find((attr: any) => attr.traits.find((t: any) => t.attr === filter.attr && t.name === filter.name));

        if (attr) {
            return attr.traits.find((t: any) => t.attr === filter.attr && t.name === filter.name)
        }
    }

    initAttributes() {
        this.attributes.forEach((attr:any) => attr.traits.forEach((t:any) => t.count = 0));
        this.pigs.forEach((pig: IPig) => {
            pig.attributes.forEach((attr: IAttribute) => {
                const currentAttr = this.attributes.find((a: any) => a.name === attr.trait_type);
                const defaultTrait = {
                    name: attr.value,
                    attr: attr.trait_type,
                    checked: false,
                    count: 1
                };

                if (!currentAttr) {
                    this.attributes.push({
                        name: attr.trait_type,
                        collapsed: false,
                        traits: [defaultTrait]
                    })
                } else {
                    const currentTrait = currentAttr.traits.find((t: any) => t.name === attr.value);

                    if (!currentTrait) {
                        currentAttr.traits.push(defaultTrait);
                    } else {
                        currentTrait.count += 1;
                    }
                }
            });
        });
        this.attributes.forEach((attr: any) => {
            attr.traits = attr.traits
                .filter((t:any) => t.name !== "???");
            attr.traits
                .sort((a: any, b: any) => b.name < a.name ? 1 : b.name > a.name ? -1 : 0);
        });
        this.attributes.sort((a: any, b: any) => b.name < a.name ? 1 : b.name > a.name ? -1 : 0)
    }

    initData() {
        this.initPigs();
        this.initAttributes();
    }

    init() {
        this.initData();
    }

    beforeMount() {
        this.init();
    }
}
